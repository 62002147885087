const URL = process.env.GATSBY_URL
    ? process.env.GATSBY_URL
    : process.env.GATSBY_VERCEL_URL
    ? `https://${process.env.GATSBY_VERCEL_URL}/`
    : `https://solublestudio.com/`;

const IS_PREVIEW =
    process.env.GATSBY_VERCEL_ENV &&
    process.env.GATSBY_VERCEL_ENV !== 'production';

const IS_PREVIEW_SERVER =
    process.env.VERCEL_ENV && process.env.VERCEL_ENV !== 'production';

module.exports = {
    URL,
    IS_PREVIEW,
    IS_PREVIEW_SERVER,
};
