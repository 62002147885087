import React from 'react';
import { Container, Title } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import Slider, { SliderProps } from '../../tech/Slider';
import CardQuote, { CardQuoteProps } from '../../molecules/CardQuote';

export interface CardQuoteSectionProps extends SliderProps {
    quotes: CardQuoteProps[];
    title?: string;
    pt?: string;
}

export default function CardQuoteSection({
    quotes,
    title,
    pt,
    ...props
}: CardQuoteSectionProps) {
    return (
        <section className={cls('pb-extra', `pt-${pt || 'xxxl'}`)}>
            {!!title && (
                <Container>
                    <Title component="h4" className="mb-xg font-b400-regular">
                        {title}
                    </Title>
                </Container>
            )}
            <Slider {...props}>
                {quotes.map((quote, i) => (
                    <CardQuote key={`quote-${i}`} {...quote} />
                ))}
            </Slider>
        </section>
    );
}
