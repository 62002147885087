import React, {
    useState,
    useRef,
    useCallback,
    useEffect,
    HTMLAttributes,
} from 'react';

import {
    Col,
    Row,
    Button,
    Container,
    TextFieldSimple,
    MessageInlineProps,
} from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import TitleSection from '../TitleSection';

import styles from './styles.module.scss';

import { Category } from '../../../types';

import SectionTheme, { SectionThemeProps } from '../../molecules/SectionTheme';

export interface ActivableCategory extends Category {
    active: boolean;
}

export interface BlogSearchSectionProps
    extends Omit<SectionThemeProps, 'onChange'> {
    categories: ActivableCategory[];
    searchQuery: string;
    isLoading?: boolean;
    onChange?: (e: string) => void;
    onClear?: () => void;
    onSubmit?: (e: React.SyntheticEvent) => void;
    translations?: {
        [key: string]: string;
    };
    showForm?: boolean;
    title?: string;
    description?: string;
    onChangeCategory?: (id: string, slug?: string) => void;
}

export default function BlogSearchSection({
    categories,
    searchQuery = '',
    isLoading = false,
    onChange,
    onClear,
    onSubmit,
    translations = {},
    showForm = true,
    title,
    description,
    onChangeCategory,
    ...props
}: BlogSearchSectionProps) {
    const [stateCategories, setStateCategories] = useState(
        () => categories ?? [],
    );
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (categories?.length) {
            setStateCategories(categories);
        }
    }, [categories]);

    const onClickTag = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        id: string,
        slug?: string,
    ) => {
        e?.preventDefault();

        setStateCategories((prevCategories) =>
            prevCategories.map((category) => ({
                ...category,
                active: category?.id === id,
            })),
        );

        if (inputRef?.current) {
            inputRef.current.value = '';
        }

        slug = slug?.endsWith('/') ? slug : `${slug}/`;

        if (typeof onChangeCategory === 'function') {
            onChangeCategory(id, slug);
        }
    };

    const getHelpText = useCallback(() => {
        return isLoading
            ? translations?.['blog.loading.text']
            : searchQuery
            ? `${
                  translations['blog.results.text'] ??
                  `Resultados de “${searchQuery}”`
              }`
            : '';
    }, [translations, searchQuery, isLoading]);

    return (
        <>
            <SectionTheme {...props} className={'pt-huge'}>
                <Container>
                    {showForm && (
                        <form className={cls('mb-xxl')} onSubmit={onSubmit}>
                            <TextFieldSimple
                                type="text"
                                name="search"
                                id="search"
                                value={searchQuery ?? ''}
                                placeholder={
                                    translations['placeholder'] ?? 'Buscador'
                                }
                                onChange={(e) => {
                                    if (typeof onChange === 'function') {
                                        onChange(e.target.value);
                                    }
                                }}
                                ref={inputRef}
                                leftIcon={
                                    searchQuery?.length > 3 ? 'close' : 'search'
                                }
                                help={
                                    {
                                        children: getHelpText(),
                                        className: 'position-xs-absolute',
                                    } as MessageInlineProps
                                }
                                leftIconButtonProps={
                                    {
                                        type: 'submit',
                                        ...(!!searchQuery && {
                                            onClick: (e) => {
                                                e.preventDefault();
                                                if (
                                                    typeof onClear ===
                                                    'function'
                                                ) {
                                                    onClear();
                                                }
                                            },
                                        }),
                                    } as HTMLAttributes<HTMLButtonElement>
                                }
                            />
                        </form>
                    )}
                    <Row>
                        <Col fullWidth={{ xs: 'all', md: 'none' }}>
                            <div className={cls(styles['tags'])}>
                                {stateCategories.map((tag, i) => (
                                    <Button
                                        key={`tag-${i}`}
                                        {...(showForm && {
                                            onClick: (e) =>
                                                onClickTag(
                                                    e,
                                                    tag?.id,
                                                    tag?.slug,
                                                ),
                                        })}
                                        href={tag?.slug}
                                        size="text"
                                        variant="secondary"
                                        {...(!!tag?.active && {
                                            disabled: true,
                                        })}
                                        className={styles.tag}>
                                        {tag.title}
                                    </Button>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </SectionTheme>
            {(title || description) && (
                <TitleSection
                    uppercase
                    size="large"
                    title={`${title}`}
                    {...(!!description && {
                        subtitle: `${description}`,
                    })}
                />
            )}
        </>
    );
}
