import React, { HTMLAttributes, useCallback } from 'react';

import {
    Row,
    Col,
    Container,
    Button,
    ColProps,
    Title,
} from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import styles from './styles.module.scss';
import usePagination from '../../../hooks/usePagination';

import CardMedia, { CardMediaProps } from '../../molecules/CardMedia';

export interface CardMediaSectionProps extends HTMLAttributes<HTMLElement> {
    cards: CardMediaProps[];
    schema?: 'default' | '2-2';
    viewMoreLabel?: string;
    title?: string;
    pt?: string;
    pb?: string;
}

export default function CardMediaSection({
    cards = [],
    className,
    schema = 'default',
    viewMoreLabel = 'Ver más',
    title,
    pt,
    pb,
    ...props
}: CardMediaSectionProps) {
    const { itemsSliced, hasMore, loadMore } = usePagination(
        cards,
        schema === '2-2' ? 6 : cards.length,
    );

    const getColWidth = useCallback(
        (colNumber): ColProps['col'] => {
            if (schema === '2-2') {
                return {
                    md: 4,
                };
            }

            const length = cards.length;

            const isLengthOdd = length % 2 === 1;
            const isLengthThreeMultiple = length % 3 === 0;
            const isLengthThreePlusOneMultiple = length % 3 === 1;
            const isLast = colNumber + 1 === length;

            return {
                md: isLengthOdd && !colNumber ? 8 : 4,
                lg: isLengthThreeMultiple
                    ? 4
                    : !colNumber || (isLengthThreePlusOneMultiple && isLast)
                    ? 8
                    : 4,
            };
        },
        [cards, schema],
    );

    return (
        <section
            {...props}
            className={cls(className, pt && `pt-${pt}`, pb && `pb-${pb}`)}>
            <Container>
                {!!title && (
                    <Title component="h4" className="mb-xg font-b400-regular">
                        {title}
                    </Title>
                )}
                <Row className={styles.row}>
                    {itemsSliced.map((card, i) => (
                        <Col
                            key={`card-${i}`}
                            className={styles.col}
                            col={getColWidth(i)}>
                            <CardMedia {...card} />
                        </Col>
                    ))}
                </Row>
                {hasMore && (
                    <Button
                        fullWidth
                        className="mt-xxl"
                        component="button"
                        onClick={loadMore}
                        variant="secondary"
                        size="text">
                        {viewMoreLabel}
                    </Button>
                )}
            </Container>
        </section>
    );
}
