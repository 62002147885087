import React, {
    useEffect,
    useRef,
    useState,
    HTMLAttributes,
    useCallback,
} from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

import {
    TextFieldSimple,
    TextFieldSimpleProps,
    TextFieldArea,
    TextFieldAreaProps,
    SelectionControlCheckbox,
    SelectionControlCheckboxProps,
    Button,
    MessageInline,
} from '@soluto-design/react';

import cls from '@soluto-design/styles/cls';
import styles from './styles.module.scss';

interface FormPropsField {
    type: 'simple' | 'area' | 'checkbox' | 'hidden';
    props:
        | TextFieldSimpleProps
        | TextFieldAreaProps
        | SelectionControlCheckboxProps
        | HTMLAttributes<HTMLInputElement>;
}

export interface FormProps extends HTMLAttributes<HTMLFormElement> {
    fields?: FormPropsField[];
    method?: 'POST' | 'GET';
    action?: string;
    submitText?: string;
    successText?: string;
    errorText?: string;
}

export default function Form({
    onSubmit,
    fields,
    submitText = 'Send',
    successText = 'Success',
    errorText = 'Error',
    ...props
}: FormProps) {
    const recaptchaRef = useRef();
    const formRef = useRef<HTMLFormElement>();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const onSubmitWithReCAPTCHA = useCallback(
        async (e) => {
            e.preventDefault();

            setLoading(true);

            if (!recaptchaRef?.current) {
                return;
            }

            let token = null;

            try {
                token = await (recaptchaRef.current as any).executeAsync();
            } catch (e) {}

            if (!token) {
                setError(true);
                return;
            }

            const form = e.target;
            const formData = new FormData(form);
            const data = {};

            formData.forEach((value, key) => {
                data[key] = value === 'on' ? true : value;
            });

            try {
                const res = await fetch(form.action, {
                    method: form.method || 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                });

                await res.json();
                if (res.status === 200) {
                    setSuccess(true);
                } else {
                    setError(true);
                }
            } catch (e) {
                setError(true);
            }
        },
        [recaptchaRef, setLoading],
    );

    useEffect(() => {
        if (error || success) {
            setLoading(false);
        }
    }, [error, success, setLoading]);

    useEffect(() => {
        if (success && formRef?.current) {
            formRef.current.reset();
            let timeout: any = null;

            timeout = setTimeout(() => {
                setSuccess(false);
                timeout = null;
            }, 15000);

            return () => {
                if (timeout) {
                    clearTimeout(timeout);
                }
            };
        }
    }, [success, formRef, setSuccess]);

    return (
        <form ref={formRef} onSubmit={onSubmitWithReCAPTCHA} {...props}>
            {fields?.map((field, i) => {
                switch (field.type) {
                    case 'simple':
                        return (
                            <TextFieldSimple
                                key={`field-${i}`}
                                className={styles.text}
                                {...(field.props as TextFieldSimpleProps)}
                            />
                        );
                    case 'area':
                        return (
                            <TextFieldArea
                                key={`field-${i}`}
                                className={styles.text}
                                {...(field.props as TextFieldAreaProps)}
                            />
                        );
                    case 'checkbox':
                        return (
                            <SelectionControlCheckbox
                                key={`field-${i}`}
                                className={styles.checkbox}
                                {...(field.props as SelectionControlCheckboxProps)}
                            />
                        );
                    case 'hidden':
                        return (
                            <input
                                key={`field-${i}`}
                                type="hidden"
                                {...(field.props as HTMLAttributes<HTMLInputElement>)}
                            />
                        );
                }

                return null;
            })}
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LfbgnUnAAAAAElNn4Z_xp7t8Is1JnVDg7Cz_Ak_"
            />
            <Button
                component="button"
                type="submit"
                variant="secondary"
                size="text"
                className="mt-xxl"
                {...(!!(loading || error) && { disabled: true })}>
                {submitText}
            </Button>
            {!!error && (
                <MessageInline
                    variant="error"
                    className={cls(
                        'mt-xs position-xs-absolute',
                        styles.message,
                    )}>
                    {errorText}
                </MessageInline>
            )}
            {!!success && (
                <MessageInline
                    variant="success"
                    className={cls(
                        'mt-xs position-xs-absolute',
                        styles.message,
                    )}>
                    {successText}
                </MessageInline>
            )}
        </form>
    );
}
