import React from 'react';

import BlogListSection, { BlogListSectionProps } from '../BlogListSection';
import BlogSearchSection, {
    BlogSearchSectionProps,
} from '../BlogSearchSection';

import styles from './styles.module.scss';

export interface BlogSectionProps
    extends BlogListSectionProps,
        BlogSearchSectionProps {}

export default function BlogSection({
    articles,
    categories,
    searchQuery,
    isLoading,
    translations,
    onChange,
    onClear,
    onSubmit,
    showForm = true,
    title,
    description,
    pagination,
    showPagination,
    onChangeCategory,
    loadMore,
}: BlogSectionProps) {
    return (
        <div className={showForm ? styles.wrapperWithForm : ''}>
            <BlogSearchSection
                categories={categories}
                searchQuery={searchQuery}
                isLoading={isLoading}
                translations={translations}
                onChange={onChange}
                onClear={onClear}
                onSubmit={onSubmit}
                showForm={showForm}
                title={title}
                description={description}
                onChangeCategory={onChangeCategory}
            />
            <BlogListSection
                articles={articles}
                translations={translations}
                pagination={pagination}
                showPagination={showPagination}
                loadMore={loadMore}
            />
        </div>
    );
}
