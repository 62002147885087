import React, { HTMLAttributes } from 'react';

import { Text, Button, Title, Icon } from '@soluto-design/react';
import Tag from '../../atoms/Tag';
import cls from '@soluto-design/styles/cls';
import Link, { LinkProps } from '@soluto-design/link';
import Picture, { PictureProps } from '@soluto-design/picture';
import Video, { VideoProps } from '../Video';

import styles from './styles.module.scss';

export interface CardMediaProps extends HTMLAttributes<HTMLElement> {
    image?: PictureProps;
    video?: VideoProps['video'];
    title: string;
    subtitle?: string;
    tags?: string[];
    href: LinkProps['href'];
}

export default function CardMedia({
    image,
    video,
    title,
    subtitle,
    tags,
    href,
    className,
}: CardMediaProps) {
    return (
        <article className={cls(className)}>
            <Link
                href={href}
                data-theme="black"
                className={cls(
                    'display-xs-block mb-xs position-xs-relative p-xs no-decoration',
                    styles.imageWrapper,
                    !image && styles.noImage,
                )}>
                {image ? (
                    <Picture
                        layout="fill"
                        className={styles.image}
                        src={image}
                        alt={image?.title || title}
                        sizes="(max-width: 414px) 764px, (max-width: 1023px) 1336px, 2720px"
                    />
                ) : video ? (
                    <Video video={video} className={styles.video} />
                ) : (
                    <Icon
                        className={styles.icon}
                        size={64}
                        icon="brand-isotype"
                    />
                )}
                <div className={cls('display-xs-flex justify-xs-between')}>
                    <div>
                        {tags?.map((tag, i) => (
                            <Tag
                                key={`tag-${i}`}
                                className={cls('mr-tiny mb-tiny', styles.tag)}
                                size="small"
                                variant="fill">
                                {tag}
                            </Tag>
                        ))}
                    </div>
                    <Button
                        className={styles.btn}
                        rightIcon="arrow-corner--type-right-up"
                        variant="secondary"
                        size="icon"
                        component="span"
                    />
                </div>
            </Link>
            <Link
                href={href}
                className={cls('no-decoration display-xs-inline-block')}>
                <Title
                    component="h4"
                    className={cls('font-b400-regular pr-xs', styles.title)}>
                    {title}
                </Title>
            </Link>
            {!!subtitle && (
                <Text
                    component="p"
                    className={cls(
                        'font-b500-regular mt-xxs pr-xs',
                        styles.subtitle,
                    )}>
                    {subtitle}
                </Text>
            )}
        </article>
    );
}
