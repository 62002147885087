const React = require('react');

const { AppProvider, useAppState } = require('./src/hooks/useAppState');
const {
    VideoAskProvider,
    VideoAsk,
    Blackboard,
} = require('@solublestudio/soluble-design-system');
const { clearAllBodyScrollLocks } = require('body-scroll-lock');
const ReactDOM = require('react-dom/client');

require('@soluto-design/styles/base');

exports.wrapRootElement = ({ element, props }) => {
    return (
        <VideoAskProvider>
            <AppProvider {...props}>{element}</AppProvider>
        </VideoAskProvider>
    );
};

exports.wrapPageElement = ({ element, props }) => {
    const { blackboard, color, thickness, isDrawing, isChoosing, isSharing } =
        useAppState();

    return (
        <>
            {element}
            <VideoAsk
                videoId={
                    props?.data?.page?.videoId || props?.data?.videoId || null
                }
            />
            <Blackboard
                ref={blackboard}
                color={color}
                thickness={thickness}
                isDrawing={isDrawing}
                isChoosing={isChoosing}
                isSharing={isSharing}
            />
        </>
    );
};

const event = new CustomEvent('@bb/reset');

exports.onPreRouteUpdate = () => {
    window.dispatchEvent(event);
};

function getNewRouteLang(pathname) {
    if (!pathname) {
        return null;
    }

    let parts = pathname.split('/').filter((i) => !!i);
    if (!parts.length) {
        return null;
    }

    if (['es', 'en'].indexOf(parts[0]) !== -1) {
        return parts[0];
    }

    return null;
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
    const routeLang = getNewRouteLang(location.pathname);
    if (routeLang) {
        window.document.cookie = `pr_lng=${routeLang}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
    }

    if (prevLocation) {
        clearAllBodyScrollLocks();
    }

    delete window.document.body.dataset.blur;
};

exports.replaceHydrateFunction = () => {
    return (element, container) => {
        const root = ReactDOM.createRoot(container);
        root.render(element);
    };
};
