import React, { useMemo } from 'react';
import EnrichedText, {
    EnrichedTextRowProps,
} from '../../organisms/EnrichedText';

export interface PostTemplateProps {
    title: string;
    featuredImage: any;
    rows: EnrichedTextRowProps[];
    relatedTag: any;
    postLabel?: string;
    year?: string;
    yearLabel?: string;
}

export default function PostTemplate({
    title,
    featuredImage,
    rows,
    relatedTag,
    postLabel = 'Post',
    yearLabel = 'Año',
    year,
}: PostTemplateProps) {
    const finalRows = useMemo(() => {
        if (!rows?.length) {
            return [];
        } else if (!featuredImage) {
            return rows;
        }

        return [
            {
                media: [
                    {
                        type: 'image',
                        image: featuredImage,
                    },
                ],
            },
            ...rows,
        ];
    }, [rows, featuredImage]);

    const tags = useMemo(() => {
        const tags = {};

        if (relatedTag?.length) {
            tags[postLabel] = relatedTag.map((r) => r.title);
        }

        if (year) {
            tags[yearLabel] = [year];
        }

        return tags;
    }, [relatedTag, postLabel, year, yearLabel]);

    return <EnrichedText title={title} tags={tags} rows={finalRows} />;
}
