import React, { useEffect, useState } from 'react';

import { Col, Row, Button, Container } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';

import CardMedia, { CardMediaProps } from '../../molecules/CardMedia';
import SectionTheme from '../../molecules/SectionTheme';

export interface BlogListSectionProps {
    articles: CardMediaProps[];
    translations?: {
        [key: string]: string;
    };
    showPagination?: boolean;
    pagination?: {
        nextUrl?: string;
        previousUrl?: string;
    };
    loadMore?: string | boolean;
    itemsPerPage?: number;
}

const ITEMS_PER_PAGE = 9;

export default function BlogListSection({
    articles = [],
    translations = {},
    pagination = {},
    showPagination = false,
    loadMore = false,
    itemsPerPage = ITEMS_PER_PAGE,
}: BlogListSectionProps) {
    const [skip, setSkip] = useState(
        loadMore ? itemsPerPage : articles?.length,
    );

    const [articlesSliced, setArticledSliced] = useState(() =>
        articles?.slice(0, skip),
    );

    const onClickLoadMore = (ev) => {
        ev?.preventDefault();

        setSkip((prevSkip) =>
            articles?.length > prevSkip + itemsPerPage
                ? prevSkip + itemsPerPage
                : articles?.length,
        );
    };

    useEffect(() => {
        if (loadMore) {
            setArticledSliced(articles.slice(0, skip));
        }
    }, [skip, articles, loadMore]);

    return (
        <SectionTheme className={cls('pt-xxl pb-huge')}>
            <Container>
                <Row>
                    {articlesSliced?.length
                        ? articlesSliced.map((article, i) => {
                              return (
                                  <Col
                                      col={{ md: 4, lg: 4 }}
                                      className={cls('mb-xxl')}
                                      key={`article-${article?.title}-${i}`}>
                                      <CardMedia {...article} />
                                  </Col>
                              );
                          })
                        : null}

                    {articles?.length !== articlesSliced?.length &&
                        loadMore && (
                            <Col className="display-xs-flex justify-xs-center">
                                <Button
                                    variant="secondary"
                                    size="text"
                                    href={pagination?.nextUrl}
                                    onClick={onClickLoadMore}>
                                    {typeof loadMore === 'string'
                                        ? loadMore
                                        : 'Load More'}
                                </Button>
                            </Col>
                        )}
                </Row>
                {(pagination?.previousUrl || pagination?.nextUrl) &&
                    showPagination && (
                        <div
                            className={cls(
                                'display-xs-flex justify-xs-between',
                            )}>
                            {pagination?.previousUrl ? (
                                <Button
                                    href={pagination?.previousUrl}
                                    component="a"
                                    variant="secondary"
                                    leftIcon="arrow-corner--type-left-down"
                                    size="text">
                                    {translations['blog.pagination.previous']}
                                </Button>
                            ) : (
                                <div />
                            )}
                            {pagination?.nextUrl ? (
                                <Button
                                    href={pagination?.nextUrl}
                                    variant="secondary"
                                    rightIcon="arrow-corner--type-right-up"
                                    size="text"
                                    component="a">
                                    {translations['blog.pagination.next']}
                                </Button>
                            ) : (
                                <div />
                            )}
                        </div>
                    )}
            </Container>
        </SectionTheme>
    );
}
