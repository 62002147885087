import React, { useMemo, useEffect, HTMLAttributes } from 'react';

import {
    Container,
    Row,
    Col,
    Text,
    Title,
    Button,
    Icon,
} from '@soluto-design/react';
import Link, { LinkProps } from '@soluto-design/link';
import cls from '@soluto-design/styles/cls';

import useNearScreen from '../../../hooks/useNearScreen';

import LanguageSelector, {
    AlternateLanguageProps,
} from '../../molecules/LanguageSelector';
import Logo from '../../molecules/Logo';

import styles from './styles.module.scss';

interface FooterLinksListProps extends HTMLAttributes<HTMLDivElement> {
    title: string;
    links?: LinkProps[];
    primary?: boolean;
    external?: boolean;
}

export interface FooterProps extends HTMLAttributes<HTMLElement> {
    text: string;
    copyright: string;
    contactLink: LinkProps | null;
    mainLinks: FooterLinksListProps | null;
    socialLinks: FooterLinksListProps | null;
    newsletterLinks: FooterLinksListProps | null;
    hiringLinks: FooterLinksListProps | null;
    legalLinks: LinkProps[];
    alternateLanguagesTitle: string;
    alternateLanguages?: AlternateLanguageProps[];
    iconLinkHref?: string;
}

function FooterLinksList({
    title,
    links = [],
    primary = false,
    external = false,
    className,
    ...props
}: FooterLinksListProps) {
    return (
        <div
            {...props}
            className={cls(
                'display-xs-flex flex-xs-column align-xs-start',
                className,
            )}>
            <Title
                component="h5"
                className={cls(
                    'font-c100-regular text-tertiary',
                    styles.linkTitle,
                )}>
                {title}
            </Title>
            {links.map((item, i) => (
                <Link
                    key={`link-${i}`}
                    {...(external && {
                        target: '_blank',
                        rel: 'noopener noreferrer',
                    })}
                    {...item}
                    partiallyActive
                    classes={{
                        active: styles.active,
                    }}
                    className={cls(
                        styles.link,
                        'font-b400-regular no-decoration',
                        primary && styles.primaryLink,
                        primary &&
                            'display-xs-flex flex-xs-row align-xs-center',
                    )}>
                    {item.children}
                    {primary && (
                        <Icon size={12} icon="arrow-corner--type-right-up" />
                    )}
                </Link>
            ))}
        </div>
    );
}

export default function Footer({
    text,
    copyright,
    contactLink,
    mainLinks,
    socialLinks,
    newsletterLinks,
    hiringLinks,
    legalLinks,
    alternateLanguagesTitle,
    alternateLanguages = [],
    iconLinkHref,
}: FooterProps) {
    const { isNearScreen, fromRef: rootRef } = useNearScreen(
        { threshold: 0.2 },
        false,
    );

    const rootHref = useMemo(() => {
        if (iconLinkHref) {
            return iconLinkHref;
        }

        const current = alternateLanguages.find((l) => l.active);

        return current?.href
            ? `/${current.href.split('/').filter((i) => !!i)[0]}/`
            : '/';
    }, [alternateLanguages, iconLinkHref]);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        if (isNearScreen) {
            document.body.dataset.onFooter = 'true';
            return () => {
                delete document.body.dataset.onFooter;
            };
        }
    }, [isNearScreen]);
    return (
        <footer ref={rootRef} data-theme="black" className={styles.root}>
            <Container>
                <Row>
                    <Col className={cls(styles.contactLinkCol)}>
                        {!!contactLink && (
                            <Link
                                {...contactLink}
                                partiallyActive
                                className={cls(
                                    'font-b200-regular no-decoration display-xs-flex flex-xs-row justify-xs-between align-xs-center radius-medium background-secondary',
                                    styles.contactLink,
                                )}>
                                {contactLink.children}
                                <div
                                    className={styles.contactLinkIcon}
                                    data-theme="white">
                                    <Button
                                        component="span"
                                        variant="primary"
                                        size="icon-footer"
                                        rightIcon="arrow-corner--type-right-up"
                                    />
                                </div>
                            </Link>
                        )}
                    </Col>
                    <Col
                        col={{ md: 3, lg: 3, xl: 2 }}
                        order={{ md: 2 }}
                        className="mb-xxxl">
                        <Text
                            className={cls(
                                'font-b200-regular display-xs-block mb-xs',
                                styles.text,
                            )}>
                            {text}
                        </Text>
                        <Logo
                            title="Home"
                            href={rootHref}
                            aria-label="Home"
                            className="display-xs-inline-block position-xs-relative"
                        />
                    </Col>
                    <Col
                        col={{ md: 5, lg: 4 }}
                        offset={{ lg: 1, xl: 2 }}
                        order={{ md: 2 }}
                        className={cls(
                            'display-xs-flex flex-xs-column align-xs-start flex-md-row mb-xl',
                            styles.linkCols,
                        )}>
                        {!!mainLinks && <FooterLinksList {...mainLinks} />}
                        {!!socialLinks && (
                            <FooterLinksList external {...socialLinks} />
                        )}
                    </Col>
                    <Col
                        col={{ md: 5, lg: 4 }}
                        offset={{ md: 3, lg: 0 }}
                        order={{ md: 2 }}>
                        {!!newsletterLinks && (
                            <FooterLinksList
                                {...newsletterLinks}
                                primary
                                className={cls('mb-xl', styles.newsletterLinks)}
                            />
                        )}
                        {!!hiringLinks && (
                            <FooterLinksList
                                {...hiringLinks}
                                primary
                                className={cls('mb-xl', styles.hiringLinks)}
                            />
                        )}
                    </Col>
                    <Col
                        col={{ md: 3, lg: 4 }}
                        order={{ md: 3 }}
                        className={cls(
                            'display-md-flex flex-md-row align-md-center',
                            styles.langCol,
                        )}>
                        <Title
                            component="h5"
                            className="text-tertiary font-c100-regular">
                            {alternateLanguagesTitle}
                        </Title>
                        <LanguageSelector
                            className={styles.link}
                            textClassName="font-b400-regular"
                            items={alternateLanguages}
                        />
                    </Col>
                    <Col
                        col={{ md: 3, lg: 4 }}
                        order={{ md: 2 }}
                        className="display-xs-flex flex-xs-row justify-xs-between">
                        <div
                            className={cls(
                                'display-xs-flex flex-xs-row align-xs-center',
                                styles.legalLinksWrapper,
                            )}>
                            {legalLinks.map((item, i) => (
                                <Link
                                    key={`link-${i}`}
                                    {...item}
                                    className={cls(
                                        'font-c200-regular text-tertiary no-decoration',
                                    )}
                                />
                            ))}
                        </div>
                        <Text className="display-md-none text-tertiary font-c200-regular">
                            {copyright}
                        </Text>
                    </Col>
                    <Col
                        col={{ md: 2, lg: 4 }}
                        order={{ md: 3 }}
                        className="display-xs-none display-md-flex align-xs-center">
                        <Text className="text-tertiary font-c200-regular">
                            {copyright}
                        </Text>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}
