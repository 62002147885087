import React, { HTMLAttributes, Children } from 'react';

import cls from '@soluto-design/styles/cls';
import variables from '@soluto-design/styles/variables';
import layoutStyles from '@soluto-design/styles/components/layout';

import { useKeenSlider } from 'keen-slider/react';

import styles from './styles.module.scss';
import 'keen-slider/keen-slider.min.css';

export interface SliderProps extends HTMLAttributes<HTMLDivElement> {}

export default function Slider({ children, className, ...props }: SliderProps) {
    const [sliderRef] = useKeenSlider<HTMLDivElement>({
        mode: 'free-snap',
        slides: {
            perView: 'auto',
            spacing: 12,
        },
        initial: 0,
        breakpoints: {
            [`(min-width: ${variables['layout-breakpoint-lg']}px)`]: {
                drag: false,
            },
            [`(min-width: ${variables['layout-breakpoint-xl']}px)`]: {
                drag: false,
                slides: {
                    perView: 'auto',
                    spacing: 16,
                },
            },
        },
    });

    return (
        <div
            ref={sliderRef}
            {...props}
            className={cls(
                className,
                layoutStyles.container,
                styles.wrapper,
                'keen-slider',
            )}>
            <div className={cls('keen-slider__slide', styles.border)} />
            {Children.map(children, (child, i) => (
                <div
                    key={`slide-${i}`}
                    className={cls('keen-slider__slide', styles.slide)}>
                    {child}
                </div>
            ))}
            <div className={cls('keen-slider__slide', styles.border)} />
        </div>
    );
}
